<template>
    <div class="save-user-wrapper">

        <div v-if="isLoading(['GetUserDetails', 'UploadingProfileImage', 'UpdateProfileDetails'])" class="spinner-flex"><Spinner/></div>

        <div class="grid row-15" v-if="item">
            <h3>Main details</h3>

            <div class="profile-template">
                <div class="image" :style="[ `background-image: url('${userImage}')` ]" @click="uploadProfileImage"></div>
                <div class="content">
                    <div class="name text-overflow">{{ item.name }} {{ item.lastname }}</div>
                    <div class="email text-overflow">{{ item.email }}</div>
                    <div class="password"><a href="" @click.prevent="changeUserPassword">Change password</a></div>
                </div>
            </div>

            <Input name="Name" v-model="item.name" :error="errors.name" placeholder="Enter name"/>
            <Input name="Last name" v-model="item.lastname" :error="errors.lastname" placeholder="Enter last name"/>
            <Input name="Display name" v-model="item.displayName" :error="errors.displayName" placeholder="Enter display name"/>
            <InputDropDownDatepicker v-model="item.dob" :error="errors.dob" placeholder="Select date of birth" name="Date of birth"/>
            <Input name="Email address" v-model="item.email" :error="errors.email" placeholder="Enter email address" description="Email address is used to log in"/>
        </div>

    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FilePicker from '../../mixins/FilePicker'
import ChangePassword from './ChangePassword.vue'

    export default {
        mixins: [FilePicker],
        props: ['modal', 'item', 'errors'],
        computed: {
            ...mapGetters(['user']),
            userImage() {
                return this.item.image ? this.item.image : this.DEFAULTS.USER.IMAGE;
            }
        },
        methods: {
            async getUserDetails() {
                if(!this.user) {
                    this.modal.close();
                    return;
                }
                let details = await this.user.getUserDetails();
                if(!details) {
                    this.$ShowAlert('Unfortunately, something went wrong.');
                    this.modal.close();
                    return;
                }
                this.modal.setProp('item', details);
            },
            uploadProfileImage() {
                this.createFilePicker("image/png, image/jpeg", (image) => {
                    
                    var data = new FormData();
                    data.append('image', image);
                    
                    this.ajax('UploadingProfileImage', {
                        url: `/user/image`,
                        method: 'POST',
                        data: data
                    }, (err, body) => {
                        if(err) {
                            this.$ShowAlert(body.message || 'Unfortunately, something went wrong.');
                            return;
                        }
                        this.item.image = body.image;
                        if(this.item._id === this.user._id) {
                            this.user.changeImage(body.image);
                        }
                    });

                });
            },
            changeUserPassword() {

                let modal = this.$ShowModal({
                    title: 'Change password',
                    description: 'Password must be at least 8 characters long',
                    component: ChangePassword,
                    props: {
                        item: {},
                        errors: {}
                    }
                });

                modal.onConfirm(async (props) => {
                    modal.processing(true);

                    await this.ajax('SaveUser', {
                        url: `/user/password`,
                        method: 'PUT',
                        data: props.item
                    }, (err, body) => {
                        
                        if(err) {
                            if(body.errors) modal.setProp('errors', body.errors);
                            else this.$ShowAlert(body.message || 'Something went wrong. Please, try again!');
                            return;
                        }
                        modal.close();
                        this.$ShowAlert("Password has been changed successfully.");
                    });

                    modal.processing(false);
                });
            },
        },
        mounted() {
            this.getUserDetails();
        }
    }
</script>

<style lang="scss" scoped>
.save-user-wrapper {
    padding: 20px;
    position: relative;
}

.spinner-flex {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    background: rgba(255,255,255,0.5);
    z-index: 3;
}

.profile-template {
    display: grid;
    grid-template-columns: 70px minmax(0, 1fr);
    column-gap: 20px;
    align-items: center;
    .image {
        width: 100%;
        padding-top: 100%;
        background-color: #ccc;
        background-size: cover;
        background-position: center;
        border-radius: 17px;
        cursor: pointer;
    }
    .content {
        .name {
            font-weight: 500;
            font-size: 14px;
        }
        .role {
            color: $textShade;
        }
    }
}

.password {
    margin-top: 10px;
}

.removeLink {
    color: $error;
}

</style>