<template>
    <div class="profile-holder" v-click-outside="() => { shown = false; }">

        <div class="profile-btn" :class="{ 'active' : shown }" @click="shown = !shown">
            <img :src="USER_IMAGE" class="ph-image" width="34" height="34"/>
            <div class="name text-overflow">{{ user.name }} {{ user.lastname }}</div>
            <div>
                <span class="icon" v-if="shown"><i class="fa-solid fa-chevron-up"></i></span>
                <span class="icon" v-else><i class="fa-solid fa-chevron-down"></i></span>
            </div>
        </div>

        <div>
            <Transition name="slide-fade">
                <div v-if="shown">
                    <div class="profile-pop-up">
                        
                        <div class="header">
                            <div class="image" :style="[ `background-image: url('${USER_IMAGE}')` ]"></div>
                            <div class="content">
                                <div class="name">{{ user.name }} {{ user.lastname }}</div>
                                <div class="role" v-if="user.role">{{ user.role.name }}</div>
                                <div class="role" v-else-if="user.isAdmin">Administrator</div>
                            </div>
                        </div>

                        <div class="links">
                            <a href="" @click.prevent="editProfile">Edit profile</a>
                            <a href="" class="log-out-btn" @click.prevent="user.logout()">Log out</a>
                        </div>
                        
                    </div>
                </div>
            </Transition>
        </div>

    </div>
    
</template>

<script>
import { mapGetters } from 'vuex'
import EditProfileVue from './Users/EditProfile.vue';
    export default {
        data() {
            return {
                profileTimeout: null,
                shown: false
            }
        },
        methods: {
            showProfile() {
                this.shown = !this.shown;
                clearTimeout(this.profileTimeout);
            },
            hideProfile() {
                clearTimeout(this.profileTimeout);
                this.profileTimeout = setTimeout(() => {
                    this.shown = false;
                }, 1000);
            },
            hideProfileQuick() {
                clearTimeout(this.profileTimeout);
                this.shown = false;
            },
            editProfile() {

                let modal = this.$ShowModal({
                    title: 'Update Profile',
                    description: 'Update profile details',
                    component: EditProfileVue,
                    props: {
                        item: {},
                        errors: {}
                    }
                });

                modal.onClose(async () => {
                    let details = await this.user.getUserDetails();
                    if(details) this.$store.commit('user', details);
                });

                modal.onConfirm(async (props) => {
                    modal.processing(true);

                    await this.ajax('UpdateProfileDetails', {
                        url: '/user',
                        method: 'PUT',
                        data: props.item
                    }, (err, body) => {
                        
                        if(err) {
                            if(body.errors) modal.setProp('errors', body.errors);
                            else this.$ShowAlert(body.message || 'Something went wrong. Please, try again!');
                            return;
                        }
                        modal.close();
                    });

                    modal.processing(false);
                });

            }
        },
        computed: {
            ...mapGetters(['user'])
        }
    }
</script>

<style lang="scss" scoped>

.profile-holder {
    position: relative;
    .ph-image {
        object-fit: contain;
        width: 34px;
        height: 34px;
        border-radius: 50%;
    }
    .profile-btn {
        display: flex;
        gap: 15px;
        align-items: center;
        cursor: pointer;
        height: 44px;
        border-radius: 9px;
        padding: 0 10px 0 5px;
        user-select: none;
        border: 1px solid $borderColor;
        box-shadow: $boxShadow;
        .name {
            font-weight: 500;
            max-width: 150px;
        }
        &:hover, &.active {
            background: $bg;
            color: $themeColor2;
        }
    }
}


.profile-pop-up {
    position: absolute;
    top: 100%;
    right: 0;
    width: 320px;
    padding: 20px 20px 10px 20px;
    background: #fff;
    border-radius: 9px;
    box-shadow: $boxShadow;
    display: grid;
    row-gap: 10px;
    transition: ease 0.2s;
    border: 1px solid $borderColor;
    transform: translateY(20px);
    .header {
        display: grid;
        grid-template-columns: 50px minmax(0, 1fr);
        column-gap: 20px;
        align-items: center;
        border-bottom: 1px solid $borderColor;
        padding-bottom: 10px;
        .image {
            width: 100%;
            padding-top: 100%;
            background-color: #ccc;
            background-size: cover;
            background-position: center;
            border-radius: 17px;
        }
        .content {
            .name {
                font-weight: 500;
                font-size: 14px;
            }
            .role {
                color: $textShade;
            }
        }
    }
    .links {
        display: flex;
        justify-content: space-between;
        
        .log-out-btn {
            color: $error;
        }
    }
}
</style>