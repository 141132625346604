<template>
    <div>

        <Toast/>

        <div v-if="user">
            <UserNav/>
            <div class="user-page-content">
                <router-view></router-view>
            </div>
        </div>

        <div v-else>
            <Login/>            
        </div>
        
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Login from './Login'
import UserNav from '../components/UserNav.vue'
import UserNavHR from '../components/UserNavHorizontal.vue'

    export default {
        components: {
            Login,
            UserNav,
            UserNavHR
        },
        computed: {
            ...mapGetters(['user', 'token'])
        },
        methods: {
            websocketAuthUser() {
                if(this.user && this.token) {
                    this.$WebSocket.send('/user/login', { token: this.token });
                }
            }
        },
        watch: {
            user() {
                this.websocketAuthUser();
            }
        },
        mounted() {
            this.$WebSocket.setUrl(process.env.VUE_APP_WS_URL);
            this.$WebSocket.connect();
            this.$WebSocket.on('open', 'main-component', () => {
                this.websocketAuthUser();
            });
        }
    }
</script>

<style lang="scss" scoped>
// scss
.user-page-content {
    padding-top: 60px;
}
</style>